import React, { useEffect, useState } from 'react';
import './Landing.css';
import img1 from '../assets/images/img-interacion-landing1.webp';
import img2 from '../assets/images/img-interacion-landing2.webp';
import img3 from '../assets/images/img-interacion-landing3.webp';

const images = [img1, img2, img3];

const Landing = () => {
  const [backgroundIndex, setBackgroundIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // Cambiar imagen cada 4 seg

    return () => clearInterval(interval);
  }, []);

  return (
    <div id="landing-section" style={{ backgroundImage: `url(${images[backgroundIndex]})` }}>
      <div className="content-landing">
        <h1>INNOVACIÓN EN 
          CADA ESTRUCTURA</h1>
        <p>Edificaciones de Calidad desde 2004</p>
        <button className="cta-button">
          <a href="#contact-section">COTIZA TU PROYECTO</a>
        </button>
      </div>
    </div>
  );
};

export default Landing;